<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader>
      <template #header>
        <img
          src="@/assets/plugins/dcc_plugin.png"
          alt="dcc logo"
          height="32"
        >
        {{ installed ? 'Update ' : 'Install' }} DCC
      </template>
      <template #subheader>
        Please configure the following in order to install the DCC plugin.
      </template>
    </PageHeader>

    <div class="section-header">
      Configuration
    </div>
    <div class="mb-5" style="font-size: 15px;">
      This plugin allows you to configure "Send Direct Mail" actions in automation campaigns. When a contact enters this
      action in a campaign, they will be put "on hold". An export created by this plugin will contain any contacts that
      are "on hold" in a "Send Direct Mail" action in any campaign.
      <br>
      <br>
      The contact will remain in this "on hold" status and will not proceed through the campaign until a confirmation
      file is received from DCC and processed. The import process will create a Direct Mail action and will then
      allow the contact to continue through the campaign if the mailing was successful.
      <br>
    </div>

    <CustomTextInput
      v-model="alertNotificationRecipients"
      header="Alert Notification Recipients"
      hint="A comma separated list of email addresses that should receive an alert notification if contacts have been stuck in the On Hold status for at least 2 weeks."
    />

    <validation-observer ref="autoExportConfigs">
      <AutomatedExportConfigurations
        :automated-export-configurations="exportConfigurations"
        :show-export-delta-files-checkbox="false"
        :show-create-empty-files-checkbox="false"
        :default-export-config="defaultExportConfig"
        :export-type-options="[{id: exportType, text: 'DCC Contact Export'}]"
        @add-configuration="type => addExportConfiguration({type}, 0)"
        @clone-configuration="cloneExportConfiguration"
        @delete-configuration="deleteExportConfiguration"
        @update-configuration="updateExportValue"
      />
    </validation-observer>

    <hr class="mt-7">

    <validation-observer ref="autoImportConfigs">
      <AutomatedImportConfigurations
        :id="id"
        :plugin-data="pluginData"
        :import-configurations="importConfigurations"
        :import-type="importType"
        :file-extensions="fileExtensionOptions"
        @add-configuration="addImportConfiguration"
        @delete-configuration="deleteImportConfiguration"
        @update-configuration="updateImportValue"
      />
    </validation-observer>

    <v-row class="mt-12">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          v-if="installed"
          class="custom-button custom-button--red-text px-8 mr-4"
          height="34px"
          depressed
          @click="deactivateDialog = true;"
        >
          Deactivate
        </v-btn>

        <v-btn
          class="custom-button custom-button--blue px-13"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="installPlugin(null, null, false)"
        >
          {{ installed ? "Configure" : "Install" }}
        </v-btn>
      </v-col>
    </v-row>

    <DeactivateConfirmDialog
      v-if="deactivateDialog"
      :id="id"
      :plugin="plugin"
      @dismiss="deactivateDialog = false;"
    />
  </v-container>
</template>

<script>
import importExportMixin from "@/utils/import-export-mixin.js";
import PageHeader from "@/sharedComponents/PageHeader";
import AutomatedImportConfigurations from "@/views/Plugins/components/AutomatedImportConfigurations";
import AutomatedExportConfigurations from "@/views/Plugins/components/AutomatedExportConfigurations.vue";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import { v4 as uuidv4 } from "uuid";
import DeactivateConfirmDialog from "./components/DeactivateConfirmDialog";

export default {
  name: "AddPluginDCC",
  metaInfo: {
    title: 'Install DCC'
  },
  components: {
    AutomatedImportConfigurations,
    AutomatedExportConfigurations,
    CustomTextInput,
    PageHeader,
    DeactivateConfirmDialog,
  },
  mixins: [importExportMixin],
  props: {
    id: {
      default: () => null,
      type: [Number, String],
    },
    installed: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    deactivateDialog: false,
    pluginData: null,
    pluginLogo: null,
    pluginName: null,
    fileExtensionOptions: [
      { id: 'csv', text: 'Comma-Separated Values (.csv)' },
      { id: 'txt', text: 'Text File (.txt)' },
    ],
    alertNotificationRecipients: null,
    plugin: null,
    importType: 'dcc_confirmation',
    exportType: 'dcc_contact',
    defaultExportConfig: {
      exportType: 'dcc_contact',
      // The vendor specifically requested delta files and should not receive empty files
      exportDeltaFiles: true,
      canCreateEmptyFile: false,
    },
  }),
  async created() {
    if (this.installed) {
      await this.loadAccountPlugin();
    }
  },
  methods: {
    async loadAccountPlugin() {
      const resp = await this.$rest.plugins.get_resource(this.id, {
        includeCredentials: true,
      });
      if (!resp.data) {
        return;
      }

      this.plugin = {...resp.data};
      this.pluginData = resp.data;
      this.pluginLogo = require(`@/assets/plugins/${resp?.data?.plugin?.image}`);
      this.pluginName = resp?.data?.plugin?.name;

      let credentials = resp.data.pluginConfiguration?.credentials;
      if (!credentials || Array.isArray(credentials)) {
        credentials = {};
      }

      if (resp.data.pluginConfiguration?.exportConfigurations.length) {
        this.setupExportConfigurations(resp.data.pluginConfiguration?.exportConfigurations, credentials);
      }

      if (resp.data.pluginConfiguration?.importConfigurations.length) {
        this.setupImportConfigurations(resp.data.pluginConfiguration?.importConfigurations, credentials);
      }

      if (resp.data.pluginConfiguration?.alertNotificationRecipients) {
        this.alertNotificationRecipients = resp.data.pluginConfiguration?.alertNotificationRecipients;
      }
    },
    addExportConfiguration(existingConfig) {
      this.exportConfigurations.push(this.generateExportConfiguration({
        ...existingConfig,
        ...this.defaultExportConfig,
      }));
    },
    cloneExportConfiguration(index) {
      const existingConfig = this.exportConfigurations[index] ?? null;
      this.addExportConfiguration({
        ...existingConfig,
        name: 'Clone of ' + existingConfig?.name || '',
        key: null,
        history: null,
        lastExportDate: null,
      });
    },
    addImportConfiguration(existingConfig) {
      this.importConfigurations.push({
        connectionType: existingConfig?.connectionType || null,
        directory: existingConfig?.directory || '/',
        archiveDirectory: existingConfig?.archiveDirectory || '',
        fileExtension: existingConfig?.fileExtension || this.fileExtensionOptions[0]?.id,
        credentials: {
          host: existingConfig?.credentials?.host || null,
          username: existingConfig?.credentials?.username || null,
          password: existingConfig?.credentials?.password || null,
          port: existingConfig?.credentials?.port || null,
          privateKey: existingConfig?.credentials?.privateKey || null,
          aws_region: existingConfig?.credentials?.aws_region || null,
          aws_access_id: existingConfig?.credentials?.aws_access_id || null,
          aws_secret_key: existingConfig?.credentials?.aws_secret_key || null,
          aws_bucket: existingConfig?.credentials?.aws_bucket || null,
        },
        frequency: existingConfig?.frequency || {
          type: null,
          ampm: (new Date()).getHours() >= 12 ? 'PM' : 'AM',
          hour: (new Date()).getHours(),
          minute: '00',
          timezone: 'America/New_York',
          date: [],
        },
        importType: this.importType,
        importOptions: existingConfig?.importOptions ? {...existingConfig?.importOptions} : { choice: null },
        name: existingConfig?.name || '',
        notificationRecipients: existingConfig?.notificationRecipients || '',
        key: existingConfig?.key || uuidv4(),
        isShowing: false,
        history: existingConfig?.history ? [...existingConfig?.history] : [],
        isExisting: !!existingConfig?.key,
      });
    },
    async installPlugin(expectedExportConfig, expectedImportConfig, returnResponse) {
      const pluginConfiguration = this.getFormattedImportAndExportPluginConfiguration(
        expectedExportConfig,
        expectedImportConfig
      );
      pluginConfiguration.alertNotificationRecipients = this.alertNotificationRecipients;

      let response = null;
      if (this.installed) {
        response = await this.$rest.plugins
          .put_resource(this.id, {
            isActive: true,
            pluginConfiguration,
          })
          .catch(error => {
            let error_message = null;
            let errors_list = "";
            if (error.response?.data?.errors?.length) {
              error.response.data.errors.forEach(item => {
                errors_list += (item.error + "\r \n");
              });
              error_message = errors_list;
            } else if (error.response?.data?.message) {
              error_message = error.response?.data?.message;
            }

            this.$store.commit('snackbar/showMessage', {
              content: error_message || 'Failed when trying to configure plugin',
              color: 'error',
            });
          });
      } else {
        response = await this.$rest.plugins
          .post_resource({
            plugin: +this.id,
            isActive: true,
            pluginConfiguration,
          })
          .then((resp) => {
            const selected_account = this.$store.getters["user/account"];
            this.$store.commit("user/update_selected_account", {
              ...selected_account,
              activePlugins: [...selected_account.activePlugins, resp.data],
            });

            return resp;
          })
          .catch((error) => {
            let error_message = null;
            let errors_list = "";
            if (error.response?.data?.errors?.length) {
              error.response.data.errors.forEach(item => {
                errors_list += (item.error + "\r \n");
              });
              error_message = errors_list;
            } else if (error.response?.data?.message) {
              error_message = error.response?.data?.message;
            }

            this.$store.commit('snackbar/showMessage', {
              content: error_message || 'Failed when trying to install plugin',
              color: 'error',
            });
          });
      }

      if (returnResponse) {
        return response;
      }
      if (!response?.data) {
        return;
      }

      this.$store.commit('snackbar/showMessage', {
        content: this.installed
          ? 'Plugin configured successfully'
          : 'Plugin installed successfully',
        color: 'success',
      });

      await this.$router.push({ name: 'Plugins' });
    },
  },
};
</script>

<style scoped>
.section-header {
  margin-top: 30px;
  font-size: 1.25rem;
  font-weight: bold;
}
</style>
